<template>
  <div>
    <!-- banner -->
    <div class="diary-banner banner">
      <h1 class="banner-title">日志</h1>
    </div>
    <v-card class="blog-container">
      <el-collapse v-model="activeNames">
        <!-- 如果今日不存在 则打开一个今日的填写面板-->
        <el-collapse-item
            v-if="current === 1 && isLoginBoss && this.diaryList && !this.diaryList.find(diary => diary.formattedDate === todayDiary.formattedDate)"
            :name="todayDiary.formattedDate">
          <template slot="title">
            <el-tag style="margin-right: 10px;" size="small" effect="dark">{{ '今日' }}</el-tag>
            {{ todayDiary.formattedDate + " " + todayDiary.diaryTitle }}
            <span style="margin-left: 10px" v-if="todayDiary.saving !== undefined">
              {{ todayDiary.saving ? "保存中..." : "已保存" }}
            </span>
          </template>
          <!-- 文章内容 -->
          <mavon-editor
              ref="today-md"
              v-model="todayDiary.diaryContent"
              @imgAdd="uploadImgToday"
              @change="isLoginBoss && saveChange(todayDiary)"
              @save="isLoginBoss && saveNow(todayDiary)"
              :toolbars-flag="isLoginBoss"
              :editable="isLoginBoss"
              :subfield="isLoginBoss"
              placeholder="什么都没有呢..."
              defaultOpen="preview"
          >
            <!-- 左工具栏后加入自定义按钮  -->
            <template slot="left-toolbar-after">
              <button
                  type="button"
                  @click="openUpdate(todayDiary)"
                  class="op-icon fa el-icon-s-comment"
                  aria-hidden="true"
                  title="修改标题"
              ></button>
            </template>
          </mavon-editor>
        </el-collapse-item>
        <el-collapse-item v-for="(diary,idx) of diaryList" :key="diary.diaryId" :name="diary.formattedDate">
          <template slot="title">
            <el-tag style="margin-right: 10px;" v-if="diary.formattedDate === todayDiary.formattedDate" size="small"
                    effect="dark">{{
                '今日'
              }}
            </el-tag>
            {{ diary.formattedDate + " " + diary.diaryTitle }}

            <span style="margin-left: 10px" v-if="diary.saving !== undefined">
              {{ diary.saving ? "保存中..." : "已保存" }}
            </span>
          </template>
          <!-- 文章内容 -->
          <div class="mdDiv">
            <mavon-editor

                ref="md"
                v-model="diary.diaryContent"
                @imgAdd="(pos, imgfile) => uploadImg(pos, imgfile, idx)"
                @change=" isLoginBoss && saveChange(diary)"
                @save="isLoginBoss && saveNow(diary)"
                :toolbars-flag="isLoginBoss"
                :editable="isLoginBoss"
                :subfield="isLoginBoss"
                placeholder="什么都没有呢..."
                defaultOpen="preview"
            >
              <!-- 左工具栏后加入自定义按钮  -->
              <template slot="left-toolbar-after">
                <button
                    type="button"
                    @click="openUpdate(diary)"
                    class="op-icon fa el-icon-s-comment"
                    aria-hidden="true"
                    title="修改标题"
                ></button>
              </template>

            </mavon-editor>
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-- 分页按钮 -->
      <v-pagination
          color="#00C4B6"
          v-model="current"
          :length="Math.ceil(count / 6)"
          total-visible="7"
      />
    </v-card>

    <v-dialog v-model="openUpdateTitle" :fullscreen="isMobile" max-width="420">
      <v-card style="border-radius:4px">
        <v-icon class="float-right" @click="openUpdateTitle = false">
          mdi-close
        </v-icon>
        <div class="login-wrapper">
          <v-text-field
              v-model="title"
              label="日记标题"
              placeholder="请输入日记标题"
              clearable
          />
          <!-- 按钮 -->
          <v-btn
              class="mt-4"
              block
              color="blue"
              style="color:#fff"
              @click="updateTitle(updateTitleDiary)"
          >
            修改
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {parseTime} from "../../utils/DateUtils";
import index from "vuex";

export default {
  data() {
    return {
      page: 1,
      diaryList: [],
      activeNames: [parseTime(new Date(), '{y}年{m}月{d}日')],
      current: 1,
      count: 0,
      todayDiary: {
        diaryContent: '',
        diaryTitle: '',
        formattedDate: parseTime(new Date(), '{y}年{m}月{d}日'),
      },
      updateTitleDiary: {},
      title: '',
      openUpdateTitle: false,
    }
  },
  created() {
    this.listDiary();
  },
  methods: {
    listDiary() {
      this.$axios
          .get("/diary", {
            params: {current: this.current, size: 6}
          })
          .then(({data}) => {
            this.diaryList = data.data.recordList;
            this.count = data.data.count;
          });
    },
    saveChange(val) {
      // 避免重复加入
      if (val.saving) {
        return;
      }
      let _this = this;
      val.saving = true;
      this.$axios
          .post("/diary/save", val)
          .then(({data}) => {
            if (data.flag) {
              // 延迟500ms 避免频繁发送
              setTimeout(() => {
                val.saving = false;
                _this.$forceUpdate();
              }, 1000);

            }
          }).catch(e => {
        this.$message.error("保存失败！" + e);
        val.saving = false;
      });
    },
    saveNow(val) {
      val.saving = true;
      this.$axios
          .post("/diary/save", val)
          .then(({data}) => {
            if (data.flag) {
              this.$toast({type: "success", message: "保存成功！"});
              val.saving = false;
              this.$forceUpdate();
            }
          }).catch(e => {
        this.$message.error("保存失败！" + e);
        val.saving = false;
      });
    },
    uploadImg(pos, file, idx) {
      // Output the entire component instance to the console
      var formdata = new FormData();
      formdata.append("file", file);
      this.$axios
          .post("/manager/uploadPicture", formdata)
          .then(({data}) => {
            this.$refs['md'][idx].$img2Url(pos, data.data);
          });
    },
    uploadImgToday(pos, file) {
      var formdata = new FormData();
      formdata.append("file", file);
      this.$axios
          .post("/manager/uploadPicture", formdata)
          .then(({data}) => {
            this.$refs["today-md"][0].$img2Url(pos, data.data);
          });
    },
    openUpdate(diary) {
      this.updateTitleDiary = diary;
      this.openUpdateTitle = true;
    },
    updateTitle(val) {
      val.diaryTitle = this.title;
      this.title = '';
      this.saveNow(val);
      this.openUpdateTitle = false;
    }
  },
  watch: {
    current(value) {
      this.$axios
          .get("/diary", {
            params: {current: value, size: 6}
          })
          .then(({data}) => {
            this.diaryList = data.data.recordList;
            this.count = data.data.count;
          });
    }
  },
  computed: {
    index() {
      return index
    },
    isLoginBoss() {
      return this.$store.state.userId == "1568956835686367234";
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth > 960) {
        return false;
      }
      return true;
    }
  }
}
</script>
<style scoped>
.diary-banner {
  background: url(https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/2024/02/28/3ab0a49650a744229c64fa836a0c2f74wallhaven-welokx.jpg) center center / cover no-repeat;
  background-color: #49b1f5;
}

.diary-title {
  text-align: center;
  font-size: 36px;
  line-height: 2;
}

@media (max-width: 759px) {
  .diary-title {
    font-size: 28px;
  }
}

.diary-list {
  margin: 0 1.8rem;
  list-style: none;
}

.diary-list-item {
  padding: 8px 1.8rem 8px 0;
}

.diary-list-item:before {
  display: inline-block;
  position: relative;
  left: -0.75rem;
  width: 12px;
  height: 12px;
  border: 0.2rem solid #49b1f5;
  border-radius: 50%;
  background: #fff;
  content: "";
  transition-duration: 0.3s;
}

.diary-list-item:hover:before {
  border: 0.2rem solid #ff7242;
}

.diary-list-item a:hover {
  transition: all 0.3s;
  color: #8e8cd8;
}

.diary-list-item a:not(:hover) {
  transition: all 0.3s;
}

.diary-count {
  margin-left: 0.5rem;
  font-size: 0.75rem;
  color: #858585;
}

.v-note-wrapper {
  z-index: 0 !important
}

.mdDiv {
  border: 1px solid rgba(134, 121, 121, 0.16);
}
</style>