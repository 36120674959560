import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "../../axios.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchFlag: false,
    loginFlag: false,
    registerFlag: false,
    forgetFlag: false,
    emailFlag: false,
    drawer: false,
    loginUrl: "/",
    userId: null,
    address: null,
    avatar: null,
    nickname: null,
    intro: null,
    webSite: null,
    loginType: null,
    email: null,
    articleLikeSet: [],
    commentLikeSet: [],
    blogInfo: {},
    access_token: null,
    refresh_token: null
  },
  mutations: {
    saveToken(state,{access_token,refresh_token}){
      state.access_token = access_token;
      state.refresh_token = refresh_token;
      localStorage.setItem("access_token",access_token);
      localStorage.setItem("refresh_token",refresh_token);
    },
    deleteToken(state){
      state.access_token = "";
      state.refresh_token = "";
    },
    login(state, user) {
      state.address = user.address;
      state.userId = user.id;
      state.avatar = user.avatar;
      state.nickname = user.nickname;
      state.intro = user.intro;
      state.webSite = user.webSite;
      state.articleLikeSet = user.articleLikeSet ? user.articleLikeSet : [];
      state.commentLikeSet = user.commentLikeSet ? user.commentLikeSet : [];
      state.email = user.email;
      state.loginType = user.loginType;
    },
    logout(state) {
      state.address = null;
      state.userId = null;
      state.avatar = null;
      state.nickname = null;
      state.intro = null;
      state.webSite = null;
      state.articleLikeSet = [];
      state.commentLikeSet = [];
      state.email = null;
      state.loginType = null;
      localStorage.setItem("access_token","");
      localStorage.setItem("refresh_token","");
      state.access_token = "";
      state.refresh_token = "";
    },
    saveLoginUrl(state, url) {
      state.loginUrl = url;
    },
    saveEmail(state, email) {
      state.email = email;
    },
    updateUserInfo(state, user) {
      state.nickname = user.nickname;
      state.intro = user.intro;
      state.webSite = user.webSite;
    },
    updateAvatar(state, avatar) {
      state.avatar = avatar;
    },
    checkBlogInfo(state, blogInfo) {
      state.blogInfo = blogInfo;
    },
    closeModel(state) {
      state.registerFlag = false;
      state.loginFlag = false;
      state.searchFlag = false;
      state.emailFlag = false;
    },
    articleLike(state, articleId) {
      var articleLikeSet = state.articleLikeSet;
      if (articleLikeSet.indexOf(articleId) != -1) {
        articleLikeSet.splice(articleLikeSet.indexOf(articleId), 1);
      } else {
        articleLikeSet.push(articleId);
      }
    },
    commentLike(state, commentId) {
      var commentLikeSet = state.commentLikeSet;
      if (commentLikeSet.indexOf(commentId) != -1) {
        commentLikeSet.splice(commentLikeSet.indexOf(commentId), 1);
      } else {
        commentLikeSet.push(commentId);
      }
    }
  },
  actions: {
    refreshToken() {
      //发起刷新token请求
      return axios.get("/token", {
        params: {
          access_Token: localStorage.getItem("access_token"),
          refresh_Token: localStorage.getItem("refresh_token")
        }
      }).then(res => {
        let access_token = res.data.data.access_Token;
        let refresh_token = res.data.data.refresh_Token;
        this.commit("saveToken", {access_token, refresh_token})
        return res.data;
      });
    }
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});
