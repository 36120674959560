<template>
  <div>
    <!-- banner -->
    <div class="archive-banner banner">
      <h1 class="banner-title">归档</h1>
    </div>
    <!-- 归档列表 -->
    <v-card  class="blog-container" flat>
      <el-timeline>
        <el-timeline-item  placement="top" size="large" >
          <span style="font-size: 25px">总共有  {{count}} 篇文章，继续加油！</span>
        </el-timeline-item>
        <el-timeline-item v-for="item in archiveList" :key="item.id" :timestamp="handleTime(item.createTime)" placement="top" >
          <el-card shadow="hover">
            <p @click="toArticle(item.id)" style="font-size: 18px;padding-top: 15px">{{item.articleTitle}}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <!-- 分页按钮 -->
      <v-pagination
          color="#00C4B6"
          v-model="current"
          :length="Math.ceil(count / 6)"
          total-visible="7"
      />
    </v-card>
  </div>
</template>

<script>
// import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline";
export default {
  created() {
    this.listArchives();
  },
  // components: {
  //   Timeline,
  //   TimelineItem,
  //   TimelineTitle
  // },
  data: function() {
    return {
      current: 1,
      count: 0,
      archiveList: []
    };
  },
  methods: {
    handleTime(time){
      var res=time.split(" ")[0];
      return res;
    }
    ,
    toArticle(id){
      this.$router.push('/articles/'+id)
    },
    listArchives() {
      this.$axios
          .get("/article/front/getArchiveArticles", {
            params: { current: this.current }
          })
          .then(({ data }) => {
            this.archiveList = data.data.recordList;
            this.count = data.data.count;
          });
    }
  },
  watch: {
    current(value) {
      this.$axios
          .get("/article/front/getArchiveArticles", {
            params: { current: value }
          })
          .then(({ data }) => {
            this.archiveList = data.data.recordList;
            this.count = data.data.count;
          });
    }
  }
};
</script>

<style scoped>
.archive-banner {
  background: url(https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/img/wallhaven-yx5kml-compression.jpg)
  center center / cover no-repeat;
  background-color: #49b1f5;
}
.time {
  font-size: 0.75rem;
  color: #555;
  margin-right: 1rem;
}
p{
  cursor: pointer;
}

</style>
