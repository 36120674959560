import axios from "axios";
import {Message} from 'element-ui'
import {Notification} from 'element-ui'
import Store from "./src/store/index";
import Router from "./src/router/index";

 axios.defaults.baseURL = "http://www.xuziheng.cn/api"
// axios.defaults.baseURL = "http://localhost:8080/api"
const request = axios.create({
    timeout: 10000,
    headers: {
        'Content-Type': "application/json; charset=utf-8",
    }
})

request.interceptors.request.use(config => {
    if (localStorage.getItem("access_token") !== "" && localStorage.getItem("refresh_token") !== "") {
        config.headers['Authorization'] = localStorage.getItem("access_token") + "1xzzjh0" + localStorage.getItem("refresh_token");
    }
    return config;
})

// 定义一个flag 判断是否刷新Token中
let isRefreshing = false;
// 保存需要重新发起请求的队列
let retryRequests = [];

request.interceptors.response.use(
    async function (response) {
        const res = response.data;
        if (res.code === 200) {
            return response
        } else if (res.code === 40002) {
            // 需要刷新Token 的状态 40002
            // 拿到本次请求的配置
            let config = response.config;
            //   进入登录页面的不做刷新Token 处理
            if (!isRefreshing) {
                // 改变flag状态，表示正在刷新Token中
                isRefreshing = true;
                //   刷新Token
                return Store.dispatch("refreshToken")
                    .then(res => {
                        // 设置刷新后的Token
                        config.headers['Authorization'] = res.data.access_Token + "1xzzjh0" + res.data.refresh_Token;
                        //   遍历执行需要重新发起请求的队列
                        retryRequests.forEach(cb => cb(res.data));
                        //   清空队列
                        retryRequests = [];
                        return request.request(config);
                    })
                    .catch(() => {
                        retryRequests = [];
                        Message.error("自动登录失败，请重新登录");

                        Store.commit("logout");
                        // 刷新Token 失败 清空缓存的用户信息 并调整到登录页面
                        Router.replace({
                            path: "/",
                            query: {redirect: Router.currentRoute.fullPath, code: res.code}
                        });
                    })
                    .finally(() => {
                        // 请求完成后重置flag
                        isRefreshing = false;
                    });
            } else {
                // 正在刷新token，返回一个未执行resolve的promise
                // 把promise 的resolve 保存到队列的回调里面，等待刷新Token后调用
                // 原调用者会处于等待状态直到 队列重新发起请求，再把响应返回，以达到用户无感知的目的（无痛刷新）
                return new Promise(resolve => {
                    // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                    retryRequests.push(info => {
                        // 将新的Token重新赋值
                        config.headers['Authorization'] = info.access_Token + "1xzzjh0" + info.refresh_Token;
                        resolve(request.request(config));
                    });
                });
            }
            // return new Promise(() => {});
        } else if (res.code === 40001) {
            //认证失败 退出登录
            Message.error("认证信息错误 重新登陆！");
            Store.commit("logout");
            // //刷新Token 失败 清空缓存的用户信息 并调整到登录页面
            await Router.replace({
                path: "/",
                query: {redirect: Router.currentRoute.fullPath, code: res.code}
            });
        } else {
            return response;
        }
    },
    function (error) {
        let err = {};
        if (error.response) {
            err.errcode = error.response.status;
            err.errmsg = error.response.statusText;
        } else {
            err.errcode = -1;
            err.errmsg = error.message;
        }
        if (err.errmsg === "Network Error") {
            Notification({
                message: '网络异常,连接服务器超时',
                type: 'error',
            })
        } else {
            Notification({
                message: err.errmsg,
                type: 'error',
            })
        }
        // Store.commit("finishLoading");
        return Promise.reject(err);
    }
);

export default request