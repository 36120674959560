<template>
  <div>
    <!-- banner -->
    <div class="home-banner">
      <div class="banner-container">
        <!-- 联系方式 -->
        <h1 class="blog-title animated zoomIn">
          徐子恒的个人博客
        </h1>
        <!-- 一言 -->
        <div class="blog-intro">
          {{ obj.output }} <span class="typed-cursor">|</span>
        </div>
        <!-- 联系方式 -->
        <div class="blog-contact">
          <!--          <a-->
          <!--            class="iconfont iconqq"-->
          <!--            target="_blank"-->
          <!--            href="http://wpa.qq.com/msgrd?v=3&uin=1192176811&site=qq&menu=yes"-->
          <!--          />-->
          <a
              target="_blank"
              href="https://github.com/Xzhhhhhh"
              class="ml-5 mr-5 iconfont icongithub"
          />
          <!--          <a-->
          <!--            target="_blank"-->
          <!--            href="https://gitee.com/feng_meiyu"-->
          <!--            class="iconfont icongitee-fill-round"-->
          <!--          />-->
        </div>
      </div>
      <!-- 向下滚动 -->
      <div class="scroll-down" @click="scrollDown">
        <v-icon color="#fff" class="scroll-down-effects">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>
    <!-- 主页文章 -->
    <v-row class="home-container">
      <v-col md="9" cols="12">
        <v-card
            class="animated zoomIn article-card"
            style="border-radius: 12px 8px 8px 12px"
            v-for="(item, index) of articleList"
            :key="item.id"
        >
          <!-- 文章封面图 -->
          <div :class="isRight(index)">
            <router-link :to="'/articles/' + item.id">
              <v-img
                  class="on-hover"
                  width="100%"
                  height="100%"
                  :src="item.articleCover"
              />
            </router-link>
          </div>
          <!-- 文章信息 -->
          <div class="article-wrapper">
            <div style="line-height:1.4">
              <router-link :to="'/articles/' + item.id">
                {{ item.articleTitle }}
              </router-link>
            </div>
            <div class="article-info">
              <!-- 是否置顶 -->
              <span v-if="item.isTop == 1">
                <span style="color:#ff7242">
                  <i class="iconfont iconzhiding"/> 置顶
                </span>
                <span class="separator">|</span>
              </span>
              <!-- 发表时间 -->
              <v-icon size="14">mdi-calendar-month-outline</v-icon>
              {{ item.createTime | date }}
              <span class="separator">|</span>
              <!-- 文章分类 -->
              <router-link :to="'/categories/' + item.categoryId">
                <v-icon size="14">mdi-inbox-full</v-icon>
                {{ item.categoryName }}
              </router-link>
              <span class="separator">|</span>
              <!-- 文章标签 -->
              <router-link
                  style="display:inline-block"
                  :to="'/tags/' + tag.id"
                  class="mr-1"
                  v-for="tag of item.tagDTOList"
                  :key="tag.id"
              >
                <v-icon size="14">mdi-tag-multiple</v-icon>
                {{ tag.tagName }}
              </router-link>
            </div>
            <!-- 文章内容 -->
            <div class="article-content">
              {{ item.articleContent }}
            </div>
          </div>
        </v-card>
        <!-- 无限加载 -->
        <infinite-loading @infinite="infiniteHandler">
          <div slot="no-more"/>
        </infinite-loading>
      </v-col>
      <!-- 博主信息 -->
      <v-col md="3" cols="12" class="d-md-block d-none">
        <div class="blog-wrapper">
          <v-card class="animated zoomIn blog-card mt-5">
            <div class="author-wrapper">
              <!-- 博主头像 -->
              <v-avatar size="110">
                <img class="author-avatar" :src="blogInfo.avatar"/>
              </v-avatar>
              <div style="font-size: 1.375rem">{{ blogInfo.nickname }}</div>
              <div style="font-size: 0.875rem;">{{ blogInfo.intro }}</div>
            </div>
            <!-- 博客信息 -->
            <div class="blog-info-wrapper">
              <div class="blog-info-data">
                <router-link to="/archives">
                  <div style="font-size: 0.875rem">文章</div>
                  <div style="font-size: 1.25rem">
                    {{ blogInfo.articleCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link to="/categories">
                  <div style="font-size: 0.875rem">分类</div>
                  <div style="font-size: 1.25rem">
                    {{ blogInfo.categoryCount }}
                  </div>
                </router-link>
              </div>
              <div class="blog-info-data">
                <router-link to="/tags">
                  <div style="font-size: 0.875rem">标签</div>
                  <div style="font-size: 1.25rem">{{ blogInfo.tagCount }}</div>
                </router-link>
              </div>
            </div>
            <!-- 收藏按钮 -->
            <a class="collection-btn" @click="tip = true">
              <v-icon color="#fff" size="18" class="mr-1">mdi-bookmark</v-icon>
              加入书签
            </a>
            <div class="card-info-social">
              <!--              <a-->
              <!--                class="iconfont iconqq"-->
              <!--                target="_blank"-->
              <!--                href="http://wpa.qq.com/msgrd?v=3&uin=1192176811&site=qq&menu=yes"-->
              <!--              />-->
              <a
                  target="_blank"
                  href="https://github.com/Xzhhhhhh"
                  class="ml-5 mr-5 iconfont icongithub"
              />
              <!--              <a-->
              <!--                target="_blank"-->
              <!--                href="https://gitee.com/feng_meiyu"-->
              <!--                class="iconfont icongitee-fill-round"-->
              <!--              />-->
            </div>
          </v-card>
          网站信息
          <v-card class="blog-card animated zoomIn mt-5 big">
            <div class="web-info-title">
              <v-icon size="18">mdi-bell</v-icon>
              公告
            </div>
            <div style="font-size:0.875rem">
              {{ blogInfo.notice }}
            </div>
          </v-card>
          <!--          <v-card class="blog-card animated zoomIn mt-5 big">-->
          <!--            <div class="web-info-title" style="margin-bottom: 2px">-->
          <!--              <v-icon size="18">mdi-bell</v-icon>-->
          <!--              <span style="font-size: 14px"> idea插件监听中...</span>-->
          <!--            </div>-->
          <!--            <img src="https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/img/offline.png"-->
          <!--                 style="vertical-align: middle; margin-left: 20px" height="50px" v-if="ideaOnlineStatus.status === '0'">-->
          <!--            <img src="https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/img/online.png"-->
          <!--                 style="vertical-align: middle; margin-left: 20px" height="50px" v-if="ideaOnlineStatus.status === '1'">-->
          <!--            <img src="https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/img/leave.png"-->
          <!--                 style="vertical-align: middle; margin-left: 20px" height="50px" v-if="ideaOnlineStatus.status === '2'">-->
          <!--            <span style="padding-left: 10px;" v-if="ideaOnlineStatus.status === '0'">idea 已离线</span>-->
          <!--            <span style="padding-left: 10px;" v-if=" ideaOnlineStatus.status=== '1'">idea 疯狂coding中...</span>-->
          <!--            <span style="padding-left: 10px;" v-if=" ideaOnlineStatus.status=== '2'">idea 在线中...</span>-->
          <!--            <br>-->
          <!--            <span-->
          <!--                style="font-size: 11px;padding-left: 8px;color: gray;">上次更新时间 : {{-->
          <!--                ideaOnlineStatus.recordTime-->
          <!--              }}</span>-->
          <!--          </v-card>-->
          <!-- 网站信息 -->
          <v-card class="blog-card animated zoomIn mt-5">
            <div class="web-info-title">
              <v-icon size="18">mdi-chart-line</v-icon>
              网站资讯
            </div>
            <div class="web-info">
              <div style="padding:4px 0 0">
                运行时间:<span class="float-right">{{ time }}</span>
              </div>
              <div style="padding:4px 0 0">
                总访问量:<span class="float-right">
                  {{ blogInfo.viewCount }}
                </span>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <!-- 提示消息 -->
    <v-snackbar v-model="tip" top color="#49b1f5" :timeout="2000">
      按CTRL+D 键将本页加入书签
    </v-snackbar>
  </div>
</template>

<script>
import EasyTyper from "easy-typer-js";

export default {
  created() {
    this.init();
    this.getBlogInfo();
    // 获取 idea在线状态
    this.getIdeaOnlineStatus();
    // 发起长轮询 监听idea在线状态
    // this.longPollingListenIdeaStatusChange();
    this.timer = setInterval(this.runTime, 1000);
  },
  data: function () {
    return {
      tip: false,
      time: "",
      obj: {
        output: "",
        isEnd: false,
        speed: 300,
        singleBack: false,
        sleep: 0,
        type: "rollback",
        backSpeed: 40,
        sentencePause: true
      },
      articleList: [],
      blogInfo: {},
      current: 1,
      ideaOnlineStatus: {
        status: "0",
        recordTime: null
      }
    };
  },
  methods: {
    // 初始化
    init() {
      document.title = this.$route.meta.title;
      // 一言Api进行打字机循环输出效果
      fetch("https://v1.hitokoto.cn?c=i")
          .then(res => {
            return res.json();
          })
          .then(({hitokoto}) => {
            this.initTyped(hitokoto);
          });
    },
    initTyped(input, fn, hooks) {
      const obj = this.obj;
      // eslint-disable-next-line no-unused-vars
      const typed = new EasyTyper(obj, input, fn, hooks);
    },
    scrollDown() {
      window.scrollTo({
        behavior: "smooth",
        top: document.documentElement.clientHeight
      });
    },
    runTime() {
      var timeold =
          new Date().getTime() - new Date("April 30,2022").getTime();
      var msPerDay = 24 * 60 * 60 * 1000;
      var daysold = Math.floor(timeold / msPerDay);
      var str = "";
      var day = new Date();
      str += daysold + "天";
      str += day.getHours() + "时";
      str += day.getMinutes() + "分";
      str += day.getSeconds() + "秒";
      this.time = str;
    },
    getBlogInfo() {
      this.$axios.get("/blog/blogInfo").then(({data}) => {
        this.blogInfo = data.data;
        this.$store.commit("checkBlogInfo", data.data);
      });
    },
    getIdeaOnlineStatus() {
      this.$axios.get("/idea").then(({data}) => {
        this.ideaOnlineStatus.status = data.changeUserStatusMap["1173034001@qq.com"];
        this.ideaOnlineStatus.recordTime = this.getLocalTime();
      })
    },
    async longPollingListenIdeaStatusChange() {
      await this.$axios.get("/idea/listen", {timeout: 30000}).then(({data}) => {
        if (data.change) {
          this.ideaOnlineStatus.status = data.changeUserStatusMap["1173034001@qq.com"];
        }
      })
      this.ideaOnlineStatus.recordTime = this.getLocalTime();
      this.longPollingListenIdeaStatusChange();
    },
    getLocalTime() {
      const date = new Date();
      const year = date.getFullYear().toString().padStart(4, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const minute = date.getMinutes().toString().padStart(2, '0');
      const second = date.getSeconds().toString().padStart(2, '0');
      return `${year}年${month}月${day}日 ${hour}:${minute}:${second}` // 2023-02-18 21:49:05
    },
    infiniteHandler($state) {
      let md = require("markdown-it")();
      this.$axios
          .get("/article/front/homeArticleList", {
            params: {
              current: this.current
            }
          })
          .then(res => {
            if (res.data.data.length) {
              // 去除markdown标签
              res.data.data.forEach(item => {
                item.articleContent = md
                    .render(item.articleContent)
                    .replace(/<\/?[^>]*>/g, "")
                    .replace(/[|]*\n/, "")
                    .replace(/&npsp;/gi, "");
              });
              this.articleList.push(...res.data.data);
              this.current++;
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    }
  },
  computed: {
    isRight() {
      return function (index) {
        if (index % 2 == 0) {
          return "article-cover left-radius";
        }
        return "article-cover right-radius";
      };
    }
  }
};
</script>


<style scoped>
.home-banner {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  height: 100vh;
  background: url("https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/img/wallhaven-x6z9vv-compress.jpg") center center / cover no-repeat;
  background-color: #49b1f5;
  background-attachment: fixed;
  text-align: center;
  color: #fff !important;
  animation: header-effect 1s;
}

.banner-container {
  margin-top: 43vh;
  line-height: 1.5;
  color: #eee;
}

.blog-contact a {
  color: #fff !important;
}

.card-info-social {
  line-height: 40px;
  text-align: center;
  font-size: 1.5rem;
  margin: 6px 0 -6px;
}

.left-radius {
  border-radius: 8px 0 0 8px !important;
  order: 0;
}

.right-radius {
  border-radius: 0 8px 8px 0 !important;
  order: 1;
}

.article-wrapper {
  font-size: 14px;
}

@media (min-width: 760px) {
  .blog-title {
    font-size: 2.5rem;
  }

  .blog-intro {
    font-size: 1.5rem;
  }

  .blog-contact {
    display: none;
  }

  .home-container {
    max-width: 1200px;
    margin: calc(100vh - 48px) auto 28px auto;
    padding: 0 5px;
  }

  .article-card {
    display: flex;
    align-items: center;
    height: 280px;
    width: 100%;
    margin-top: 20px;
  }

  .article-cover {
    overflow: hidden;
    height: 100%;
    width: 45%;
  }

  .on-hover {
    transition: all 0.6s;
  }

  .article-card:hover .on-hover {
    transform: scale(1.1);
  }

  .article-wrapper {
    padding: 0 2.5rem;
    width: 55%;
  }

  .article-wrapper a {
    font-size: 1.5rem;
    transition: all 0.3s;
  }
}

@media (max-width: 759px) {
  .blog-title {
    font-size: 26px;
  }

  .blog-contact {
    font-size: 1.25rem;
    line-height: 2;
  }

  .home-container {
    width: 100%;
    margin: calc(100vh - 66px) auto 0 auto;
  }

  .article-card {
    margin-top: 1rem;
  }

  .article-cover {
    border-radius: 8px 8px 0 0 !important;
    height: 230px !important;
    width: 100%;
  }

  .article-cover div {
    border-radius: 8px 8px 0 0 !important;
  }

  .article-wrapper {
    padding: 1.25rem 1.25rem 1.875rem;
  }

  .article-wrapper a {
    font-size: 1.25rem;
    transition: all 0.3s;
  }
}

.scroll-down {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.scroll-down i {
  font-size: 2rem;
}

.article-wrapper a:hover {
  color: #8e8cd8;
}

.article-info {
  font-size: 95%;
  color: #858585;
  line-height: 2;
  margin: 0.375rem 0;
}

.article-info a {
  font-size: 95%;
  color: #858585 !important;
}

.article-content {
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-wrapper {
  position: sticky;
  top: 10px;
}

.blog-card {
  line-height: 2;
  padding: 1.25rem 1.5rem;
}

.author-wrapper {
  text-align: center;
}

.blog-info-wrapper {
  display: flex;
  justify-self: center;
  padding: 0.875rem 0;
}

.blog-info-data {
  flex: 1;
  text-align: center;
}

.blog-info-data a {
  text-decoration: none;
}

.collection-btn {
  text-align: center;
  z-index: 1;
  font-size: 14px;
  position: relative;
  display: block;
  background-color: #49b1f5;
  color: #fff !important;
  height: 32px;
  line-height: 32px;
  transition-duration: 1s;
  transition-property: color;
}

.collection-btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #ff7242;
  content: "";
  transition-timing-function: ease-out;
  transition-duration: 0.5s;
  transition-property: transform;
  transform: scaleX(0);
  transform-origin: 0 50%;
}

.collection-btn:hover:before {
  transition-timing-function: cubic-bezier(0.45, 1.64, 0.47, 0.66);
  transform: scaleX(1);
}

.author-avatar {
  transition: all 0.5s;
}

.author-avatar:hover {
  transform: rotate(360deg);
}

.web-info {
  padding: 0.25rem;
  font-size: 0.875rem;
}

.scroll-down-effects {
  color: #eee !important;
  text-align: center;
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  line-height: 1.5;
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  animation: scroll-down-effect 1.5s infinite;
}

@keyframes scroll-down-effect {
  0% {
    top: 0;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
  50% {
    top: -16px;
    opacity: 1;
    filter: none;
  }
  100% {
    top: 0;
    opacity: 0.4;
    filter: alpha(opacity=40);
  }
}

.big i {
  color: #f00;
  animation: big 0.8s linear infinite;
}

@keyframes big {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
</style>
