import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";
import Article from "../views/article/Article.vue";
import Archive from "../views/archive/Archive.vue";
import Tag from "../views/tag/Tag.vue";
import Category from "../views/category/Category.vue";
import Link from "../views/link/Link.vue";
import About from "../views/about/About.vue";
import Message from "../views/message/Messsage.vue";
import ArticleList from "../components/ArticleList.vue";
import User from "../views/user/User.vue";
import OauthLogin from "../components/OauthLogin.vue";
import Daily from "../views/daily/Daily.vue";

Vue.use(VueRouter);


const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


const routes = [
    {
        path: "/",
        component: Home,
        meta: {
            title: "徐子恒的个人博客"
        }
    },
    {
        path: "/articles/:articleId",
        component: Article
    },
    {
        path: "/daily",
        component: Daily,
        meta: {
            title: "日志"
        }
    },
    {
        path: "/archives",
        component: Archive,
        meta: {
            title: "归档"
        }
    },
    {
        path: "/tags",
        component: Tag,
        meta: {
            title: "标签"
        }
    },
    {
        path: "/categories",
        component: Category,
        meta: {
            title: "分类"
        }
    },
    {
        path: "/categories/*",
        component: ArticleList
    },
    {
        path: "/links",
        component: Link,
        meta: {
            title: "友链列表"
        }
    },
    {
        path: "/about",
        component: About,
        meta: {
            title: "关于我"
        }
    },
    {
        path: "/message",
        component: Message,
        meta: {
            title: "留言板"
        }
    },
    {
        path: "/tags/*",
        component: ArticleList
    },
    {
        path: "/user",
        component: User,
        meta: {
            title: "个人中心"
        }
    },
    {
        path: "/oauth/login/qq",
        component: OauthLogin
    }
    // ,
    // {
    //   path: "/oauth/login/weibo",
    //   component: OauthLogin
    // }
];

const router = new VueRouter({
    mode: "history",
    routes
});

export default router;
