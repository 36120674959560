export default {
  "[大笑]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%98%84%E5%A4%A7%E7%AC%91%28120%20x%20120%29.png",

  "[崇拜]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%A4%A9%E5%A5%BD%E5%B4%87%E6%8B%9C%E5%93%A6%28120%20x%20120%29.gif",

  "[微笑天使]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%98%87%E5%BE%AE%E7%AC%91%E5%A4%A9%E4%BD%BF%28240%20x%20240%29.gif",

  "[笑得满地打滚]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%A4%A3%E7%AC%91%E5%BE%97%E6%BB%A1%E5%9C%B0%E6%89%93%E6%BB%9A%2860%20x%2060%29%20%281%29.gif",

  "[聚会笑脸]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%A5%B3%E8%81%9A%E4%BC%9A%E7%AC%91%E8%84%B8%2860%20x%2060%29.gif",

  "[笑哭了]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%98%82%E7%AC%91%E5%93%AD%E4%BA%86%2860%20x%2060%29.gif",

  "[笑哭了大]": "https://edu-1010xzh.oss-cn-shenzhen.aliyuncs.com/emoji/%F0%9F%98%82%E7%AC%91%E5%93%AD%E4%BA%86%28240%20x%20240%29.gif",
  //
  // "[喜欢]": "https://www.static.talkxj.com/emoji/xh.jpg",
  //
  // "[酸了]": "https://www.static.talkxj.com/emoji/sl.jpg",
  //
  // "[大哭]": "https://www.static.talkxj.com/emoji/dq.jpg?",
  //
  // "[害羞]": "https://www.static.talkxj.com/emoji/hx.jpg",
  //
  // "[无语]": "https://www.static.talkxj.com/emoji/wy.jpg",
  //
  // "[疑惑]": "https://www.static.talkxj.com/emoji/yh.jpg",
  //
  // "[调皮]": "https://www.static.talkxj.com/emoji/tiaopi.jpg",
  //
  // "[笑哭]": "https://www.static.talkxj.com/emoji/xiaoku.jpg",
  //
  // "[奸笑]": "https://www.static.talkxj.com/emoji/jianxiao.jpg",
  //
  // "[偷笑]": "https://www.static.talkxj.com/emoji/touxiao.jpg",
  //
  // "[大笑]": "https://www.static.talkxj.com/emoji/daxiao.jpg",
  //
  // "[阴险]": "https://www.static.talkxj.com/emoji/yinxian.jpg",
  //
  // "[捂脸]": "https://www.static.talkxj.com/emoji/wulian.jpg",
  //
  // "[呆]": "https://www.static.talkxj.com/emoji/dai.jpg",
  //
  // "[抠鼻]": "https://www.static.talkxj.com/emoji/koubi.jpg",
  //
  // "[惊喜]": "https://www.static.talkxj.com/emoji/jingxi.jpg",
  //
  // "[惊讶]": "https://www.static.talkxj.com/emoji/jingya.jpg",
  //
  // "[捂脸哭]": "https://www.static.talkxj.com/emoji/wulianku.jpg",
  //
  // "[妙啊]": "https://www.static.talkxj.com/emoji/miaoa.jpg",
  //
  // "[狗头]": "https://www.static.talkxj.com/emoji/goutou.jpg",
  //
  // "[滑稽]": "https://www.static.talkxj.com/emoji/huaji.jpg",
  //
  // "[吃瓜]": "https://www.static.talkxj.com/emoji/chigua.jpg",
  //
  // "[打call]": "https://www.static.talkxj.com/emoji/dacall.jpg",
  //
  // "[点赞]": "https://www.static.talkxj.com/emoji/dianzan.jpg",
  //
  // "[鼓掌]": "https://www.static.talkxj.com/emoji/guzhang.jpg",
  //
  // "[尴尬]": "https://www.static.talkxj.com/emoji/ganga.jpg",
  //
  // "[冷]": "https://www.static.talkxj.com/emoji/leng.jpg",
  //
  // "[灵魂出窍]": "https://www.static.talkxj.com/emoji/linghunchuqiao.jpg",
  //
  // "[委屈]": "https://www.static.talkxj.com/emoji/weiqu.jpg",
  //
  // "[傲娇]": "https://www.static.talkxj.com/emoji/aojiao.jpg",
  //
  // "[疼]": "https://www.static.talkxj.com/emoji/teng.jpg",
  //
  // "[吓]": "https://www.static.talkxj.com/emoji/xia.jpg?",
  //
  // "[生病]": "https://www.static.talkxj.com/emoji/shengbing.jpg",
  //
  // "[吐]": "https://www.static.talkxj.com/emoji/tu.jpg",
  //
  // "[嘘声]": "https://www.static.talkxj.com/emoji/xusheng.jpg",
  //
  // "[捂眼]": "https://www.static.talkxj.com/emoji/wuyan.jpg",
  //
  // "[思考]": "https://www.static.talkxj.com/emoji/sikao.jpg",
  //
  // "[再见]": "https://www.static.talkxj.com/emoji/zaijian.jpg",
  //
  // "[翻白眼]": "https://www.static.talkxj.com/emoji/fanbaiyan.jpg",
  //
  // "[哈欠]": "https://www.static.talkxj.com/emoji/haqian.jpg",
  //
  // "[奋斗]": "https://www.static.talkxj.com/emoji/fengdou.jpg",
  //
  // "[墨镜]": "https://www.static.talkxj.com/emoji/mojing.jpg",
  //
  // "[撇嘴]": "https://www.static.talkxj.com/emoji/piezui.jpg",
  //
  // "[难过]": "https://www.static.talkxj.com/emoji/nanguo.jpg",
  //
  // "[抓狂]": "https://www.static.talkxj.com/emoji/zhuakuang.jpg",
  //
  // "[生气]": "https://www.static.talkxj.com/emoji/shengqi.jpg",
  //
  // "[爱心]": "https://www.static.talkxj.com/emoji/aixin.jpg",
  //
  // "[胜利]": "https://www.static.talkxj.com/emoji/shengli.jpg",
  //
  // "[保佑]": "https://www.static.talkxj.com/emoji/baoyou.jpg",
  //
  // "[支持]": "https://www.static.talkxj.com/emoji/zhichi.jpg"
};
